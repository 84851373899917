<template>
    <div>
        <div v-for="question in questions" class="px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <p>{{ question.question }}</p>
                </div>
                <div class="col-10 justify-content-between d-flex">
                    <div class="custom-control custom-radio custom-control-inline" v-for="answer in question.answers">
                        <input type="radio" :id="`radio-${question.id}-${answer.id}`" :name="`question[${question.id}][answer]`" class="custom-control-input" :data-question-id="question.id" :data-answer-id="answer.id" @click="addQuestionAnswer" :value="answer.id" v-model="requestQuestions[question.id]">
                        <label class="custom-control-label" :for="`radio-${question.id}-${answer.id}`">{{ answer.answer }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group px-5 py-3 text-right">
            <button class="btn custom-btn custom-btn-left-curve mx-5" type="button" @click="sendQuestionsToUpperForm">
                Submit
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: "ServiceRequestForm",
    data() {
        return {
            requestQuestions: [],
        }
    },
    props: [
        "questions"
    ],
    methods: {
        sendQuestionsToUpperForm() {
            this.$emit('sendQuestionsToForm', {
                requestQuestions: this.requestQuestions,
            })
        }
    },
}
</script>

<style scoped>

</style>
