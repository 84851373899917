<template>
    <Loading v-model:active="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"/>

    <div class="send-query-container send-query-client">
        <div class="send-query-content-wrapper p-5">
            <h1 class="text-center text-white">Service Request</h1>
            <div class="text-center pl-4 mb-4">
                <span class="title-left bg-white"></span>
                <span class="title-center bg-white" style="width: 20%;"></span>
                <span class="title-right bg-white"></span>
                <span class="title-chip bg-white" style="width: 20px; height: 20px"></span>
            </div>
            <div class="send-query-form-wrapper position-relative scroll-container">
                <div class="top-background h-auto d-flex align-content-center h3 text-white p-4 position-sticky top-0">Service Request Home</div>
                <div>
                    <div class="p-3">
                        <button v-if="serviceRequest.status_can_be_changed" type="button" class="btn btn-general mr-2" data-bs-toggle="modal" data-bs-target="#upload-file">
                            Add Update
                        </button>
                        <a v-if="serviceRequest.status_can_be_changed" class="btn btn-general mr-2" @click="callMarkStatusAsCompletedApiEndpoint">Close SR</a>
                    </div>
                    <div>
<!--                        <div class="demo-content d-flex align-items-center">-->
<!--                            <ui-button><ui-icon class="text-warning px-2">star_rate</ui-icon></ui-button>Test-->
<!--                        </div>-->
                    </div>
                    <div>
                        <div class="row w-100 m-0 h-100">
                            <div class="col-12 col-md-7 client-chat">
                                <div class="form-group col-sm-12 h-100 p-0">
                                    <ClientChat @onRequestChange="updateRequest"
                                               :user="this.user"
                                               :request="this.serviceRequest"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-5 client-details">
                                <SRDetails :request="this.serviceRequest"/>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
    <div class="modal fade" id="upload-file" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl p-4">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title border-0 text-white">Upload File</h5>
                    <button ref="Close" type="button" class="btn-close btn" data-bs-dismiss="modal" style="background: none"
                            aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <form @submit.prevent="SendNewMessage" class="mt-5 p-4">
                    <div>
                        <label class="form-label">Message</label>
                        <textarea class="form-control" rows="5" aria-label="With textarea" v-model="newMessage"></textarea>
                    </div>
                    <div class="text-right my-5">
                        <label for="files" class="mx-3">
                            <img src="/images/file-icon.png" alt="" style="cursor: pointer">
                        </label>
                        <input type="file" name="files" id="files" class="custom-file-input-with-icon" style="display: none" multiple @change="handleFileUploads">
                        <button class="btn submit-btn ml-5" type="button" @click="SendNewMessage">Send</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<!-- upload file -->


<script>


import StatusButton from "../StatusButton";
import ClientChat from "../ClientChat";
import SRDetails from "../SRDetails";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: {
        StatusButton,
        ClientChat,
        SRDetails,
        Loading,
    },
    props: {
        justifications: {
            type: Object,
        },
        request: {
            type: Object,
        },
        user: {
            type: Object,
        },
        messages: {
            type: Array,
        }
    },
    data() {
        return {
            serviceRequest: this.request,
            fullPage: true,
            isLoading: false,
        }
    },
    methods: {
        updateRequest(request){
            this.serviceRequest = request;
        },
        handleFileUploads(e) {
            this.files = e.target.files;
        },
        callMarkStatusAsCompletedApiEndpoint() {
            this.isLoading = true;
            axios.post(`/client-users/service-request/${this.serviceRequest.id}/mark-service-request-as-completed`)
                .then((res) => {
                    this.isLoading = false;
                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500)
                })
                .catch((err) => {
                    console.log(err);
                })
            ;
        },
        SendNewMessage() {
            this.isLoading = true;
            let data = new FormData();
            data.append('service_request_id', this.serviceRequest.id);
            if (this.newMessage) {
                data.append('message', this.newMessage);
            }

            if (this.files) {
                for (let i = 0; i < this.files.length; i++) {
                    let file = this.files[i];
                    data.append(`files[${i}]`, file);
                }
            }

            axios.post(`/client-users/service-requests/${this.serviceRequest.id}/send-message`, data,
                { 'Content-Type': 'multipart/form-data' })
                .then(res => {
                    this.isLoading = false;
                    // this.oldMessages.push(res.data.response.data.message);
                    $('.btn-close').click();
                    this.newMessage = '';
                    // this.serviceRequest = res.data.response.data.SR;
                    // this.$emit("onRequestChange", this.serviceRequest);
                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500)
                })
                .catch(err => {
                    console.log(err);
                })
            ;

        },
    }
}
</script>
