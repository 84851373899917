<template>
    <div class="d-flex flex-wrap py-3">
        <StatusButton @onRequestChange="updateRequest"
                      :user-type="this.userType"
                      :justifications="this.justifications"
                      :request="this.serviceRequest"
                      :outsources="this.outsources"
                      :employees="employees"
                      :admins="this.admins"/>
    </div>
    <div class="row">
        <div class="col-7">
            <div class="form-group col-sm-12">
                <AdminChat @onStatusChange="updateRequest"
                           :user-type="this.userType"
                           :user="this.user"
                           :request="this.serviceRequest"/>
            </div>
        </div>
        <div class="col-5">
            <SRDetails :request="this.serviceRequest"/>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p id="response-message"></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import StatusButton from "./StatusButton";
import AdminChat from "./AdminChat";
import SRDetails from "./SRDetails";

export default {
    components: {
        StatusButton,
        AdminChat,
        SRDetails
    },
    props: [
        "user",
        "request",
        "justifications",
        "messages",
        "userType",
        "admins",
        "employees",
        "outsources",
    ],
    data() {
        return {
            serviceRequest: this.request,
            userType: this.userType,
        }
    },
    methods: {
        updateRequest(request){
            this.serviceRequest = request;
        }
    }
}
</script>
