<template>
    <div class="send-query-container">
        <div class="send-query-content-wrapper">
            <h1 class="text-center text-white">Send Query</h1>
            <hr class="text-white mx-auto" style="height: 10px">
            <div class="send-query-form-wrapper">
                <div class="top-background"></div>
                <input type="text" class="send-query-subject-text-input" placeholder="Subject" v-model="subject">
                <textarea name="message" class="send-query-message-text-area" placeholder="Message...." v-model="message"></textarea>
                <div class="text-right my-5">
                    <span>{{ filesMessage }}</span>
                    <label for="files" class="mx-3">
                        <img src="/images/file-icon.png" alt="" style="cursor: pointer">
                    </label>
                    <input type="file" name="files" id="files" class="custom-file-input-with-icon" style="display: none" multiple @change="handleFileUploads">
                    <button class="btn btn-primary custom-btn custom-btn-left-curve mx-5" type="button" @click="callSendQueryEndpoint">Send</button>
                </div>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
    <div class="modal fade" id="errorsModal" tabindex="-1" role="dialog" aria-labelledby="errorsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="errors"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 class="text-center">Your query was sent successfully</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "SendQueryForm",
        data() {
            return {
                subject: '',
                message: '',
                files: null,
                csrfToken: document.querySelector('meta[name=csrf-token]').content,
                filesMessage: 'No Attached Files',
            }
        },
        methods: {
            handleFileUploads(e) {
                this.files = e.target.files;
                this.filesMessage = `${this.files.length} Files Attached`;
            },
            callSendQueryEndpoint() {
                $('.errors').html('');
                let data = new FormData();
                let errors = [];
                if (this.subject === '') {
                    errors.push('The subject is required');
                }

                if (this.message === '') {
                    errors.push('The message is required');
                }

                if (errors.length > 0) {
                    let errorsDiv = document.querySelector('.errors');
                    errors.map((error) => {
                        let h4 = document.createElement('h4')
                        h4.innerText = error;
                        h4.classList.add('text-danger');
                        errorsDiv.append(h4);
                    })
                    $('#errorsModal').modal('show');
                    return;
                }

                data.append('_token', this.csrfToken);
                data.append('subject', this.subject);
                data.append('message', this.message);

                if (this.files) {
                    for (let i = 0; i < this.files.length; i++) {
                        let file = this.files[i];
                        data.append(`files[${i}]`, file);
                    }
                }

                console.log(data);

                axios.post('/client-users/send-query', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.subject = '';
                    this.message = '';
                    this.files = null;
                    $('#successModal').modal('show');
                })
            }
        }
    }
</script>

<style scoped>

</style>
