<template>

    <Loading v-model:active="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"/>

    <!-- Button trigger modal -->
    <button v-if="serviceRequest.status_can_be_changed" type="button" class="btn btn-general shadow-sm mr-2" data-bs-toggle="modal" data-bs-target="#upload-file">
        Upload File
    </button>

    <button v-if="serviceRequest.should_ask_for_justification && serviceRequest.status_can_be_changed"  type="button" class="btn btn-general shadow-sm mx-2" data-bs-toggle="modal" data-bs-target="#justification">
        Justification
    </button>

    <button v-if="serviceRequest.status_can_be_changed" type="button" class="btn btn-general mx-2 status-btn shadow-sm" :class="this.serviceRequest.status === 'site_visit' ? 'active' : ''" @click="openSiteVisitModal">
        Site Visit
    </button>
    <button v-if="serviceRequest.status_can_be_changed" type="button" class="btn btn-general mx-2 status-btn shadow-sm"  :class="this.serviceRequest.status === 'no_solution' ? 'active' : ''" @click="openNoSolutionModal">
        No Solution
    </button>
    <button v-if="serviceRequest.status_can_be_changed && this.userType == 'admin'" type="button" class="btn btn-general mx-2 status-btn shadow-sm" @click="openReassignServiceRequestModal">
        Re-assign
    </button>
    <!-- justification -->
    <div class="modal fade" id="justification" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title border-0 text-white" id="staticBackdropLabel ">Add Justification Reacon</h5>
                    <button ref="Close" type="button" class="btn-close btn" data-bs-dismiss="modal" style="background: none"
                            aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <form @submit.prevent="AddJustification" >
                    <div class="modal-body" style="min-height: 60vh">
                        <ul class="nav nav-pills mb-3 py-4" id="pills-tab" role="tablist">
                            <li class="nav-item mr-2" role="presentation">
                                <button @click="resetJustification" class=" active btn btn-general" id="pills-home-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                        aria-selected="true">choose From Exists
                                </button>
                            </li>
                            <li class="nav-item mx-2" role="presentation">
                                <button @click="resetJustification" class="btn btn-general" id="pills-profile-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-profile" type="button" role="tab"
                                        aria-controls="pills-profile" aria-selected="false">Write New Reason
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                 aria-labelledby="pills-home-tab">
                                <select class="form-control form-control-lg px-3" v-model="delayReasonId">
                                    <option disabled value="">Select Reason</option>
                                    <option v-for="(reason, index) in reasons" :key="index" v-bind:value="reason.id">
                                        {{reason.reason}}
                                    </option>
                                </select>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                 aria-labelledby="pills-profile-tab">
                                <div class="mb-3">
                                    <label class="form-label">Delay Justification</label>
                                    <textarea style="width: 100%; height: 150px" v-model="delayReason"
                                              class="form-control"></textarea>
                                    <p class="d-none text-danger" id="justification-reason-errors"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top-0">
                        <button type="submit" class="btn submit-btn">ADD</button>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <!-- upload file -->
    <div class="modal fade" id="upload-file" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl p-4">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title border-0 text-white">Upload File</h5>
                    <button ref="Close" type="button" class="btn-close btn" data-bs-dismiss="modal" style="background: none"
                            aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <form @submit.prevent="SendNewMessage" class="mt-5 p-4">
                    <div>
                        <label class="form-label">Message</label>
                        <textarea class="form-control" rows="5" aria-label="With textarea" v-model="newMessage"></textarea>
                    </div>
                    <div class="text-right my-5">
                        <label for="files" class="mx-3">
                            <img src="/images/file-icon.png" alt="" style="cursor: pointer">
                        </label>
                        <input type="file" name="files" id="files" class="custom-file-input-with-icon" style="display: none" multiple @change="handleFileUploads">
                        <button class="btn submit-btn ml-5" type="button" @click="SendNewMessage">Send</button>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <!-- Site Visit Modal -->
    <div class="modal fade" id="siteVisitModal" tabindex="-1" aria-labelledby="siteVisitModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title border-0 text-white" id="siteVisitModalLabel">Site Visit</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h3>Is this site visit included in a contract or is it for fees?</h3>
                    <div class="text-center">
                        <button class="btn btn-primary" v-if="request.user.has_site_visits_left_in_contract_interval" @click="callMarkStatusAsSiteVisitApiEndpoint">In Contract</button>
                        <button class="btn btn-primary" v-if="!request.user.has_site_visits_left_in_contract_interval" @click="callMarkStatusAsSiteVisitApiEndpoint">For Fees</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- No Solution Modal -->

    <div class="modal fade" id="noSolutionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl p-4">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title border-0 text-white">No Solution</h5>
                    <button ref="Close" type="button" class="btn-close btn" data-bs-dismiss="modal" style="background: none"
                            aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <form class="mt-5 p-4">
                    <div>
                        <label class="form-label">Resolution Note</label>
                        <textarea class="form-control" rows="5" aria-label="With textarea" v-model="resolutionNote"></textarea>
                    </div>
                    <div class="text-right my-5">
                        <button class="btn submit-btn ml-5" type="button" @click="callMarkStatusAsNoSolutionApiEndpoint">Send</button>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <!-- Re-assign Service Request Modal -->

    <div class="modal fade" id="reassignServiceRequestModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl p-4">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title border-0 text-white">Re-assign Service Request</h5>
                    <button ref="Close" type="button" class="btn-close btn" data-bs-dismiss="modal" style="background: none"
                            aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <form @submit.prevent class="mt-5 p-4">
                    <div>
                        <label class="form-label" for="assignee_type">New Assignee Type</label>
                        <select name="admin_user" id="assignee_type" class="form-control" @change="changeAssigneeUserType">
                            <option disabled value="">Select Assignee Type</option>
                            <option v-for="(assigneeType, index) in availableUserTypes" :key="index" v-bind:value="assigneeType" style="text-transform: capitalize">
                                {{assigneeType}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label class="form-label" for="assignee_id">New Assignee</label>
                        <select id="assignee_id" class="form-control" v-model="assigneeId">
                            <option disabled value="">Select Assignee</option>
                            <option v-for="(assignee, index) in assigneesList" :key="index" v-bind:value="assignee.id">
                                {{assignee.name}}
                            </option>
                        </select>
                    </div>
                    <div class="text-right my-5">
                        <button class="btn submit-btn ml-5" type="button" @click="callReassignServiceRequestApiEndpoint">Send</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {defineEmits} from "vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


// const emit = defineEmits(["onStatusChange"]);

export default {
    components: {
        Loading,
    },
    props: {
        justifications: {
            type: Object,
        },
        request: {
            type: Object,
        },
        admins: {
            type: Object,
        },
        userType: {
            type: String,
        },
        employees: {
            type: Object,
        },
        outsources: {
            type: Object,
        }
    },
    emits: ["onRequestChange"],
    data() {
        return {
            serviceRequest: this.request,
            reasons: this.justifications,
            delayReasonId: '',
            delayReason: '',
            newMessage: '',
            resolutionNote: '',
            files: null,
            assigneeId: this.request.assignee_id,
            fullScreen: true,
            isLoading: false,
            availableUserTypes: [
                'admin',
                'employee',
                'outsource'
            ],
            assignedUserType: 'admin',
            assignedUserId: '',
            assigneesList: this.admins
        }
    },
    methods: {

        handleFileUploads(e) {
            this.files = e.target.files;
        },
        SendNewMessage() {
            this.isLoading = true;
            let data = new FormData();
            data.append('service_request_id', this.serviceRequest.id);
            data.append('message', this.newMessage);
            let url = '';

            switch (this.userType) {
                case 'admin':
                    url = '/admin/send-sr-message-admin';
                    break;
                case 'employee':
                    url = '/employee/send-sr-message';
                    break;
                case 'outsource':
                    url = '/outsource/send-sr-message';
                    break;
            }

            if (this.files) {
                for (let i = 0; i < this.files.length; i++) {
                    let file = this.files[i];
                    data.append(`files[${i}]`, file);
                }
            }

            axios.post(url, data,
                { 'Content-Type': 'multipart/form-data' })
                .then(res => {
                    // this.oldMessages.push(res.data.response.data.message);
                    this.isLoading = false;
                    this.newMessage = '';
                    this.serviceRequest = res.data.response.data.SR;
                    this.$emit("onRequestChange", this.serviceRequest);
                    $('#upload-file').modal('hide');

                })
                .catch(err => {
                    console.log(err);
                })
            ;

        },
        updateStatus(status){
            this.isLoading = true;

            const data = {
                status: status,
                service_request_id: this.request.id,
            }
            axios.post('/admin/service-requests/update-status', data,
                {'Content-Type': 'multipart/form-data'})
                .then(res => {
                    this.isLoading = false;
                    this.serviceRequest.status = status;
                    this.status = status;
                    this.$emit("onRequestChange", this.serviceRequest);

                })
                .catch(err => {
                    console.log(err);
                })
            ;
        },
        resetJustification(){
            this.delayReasonId = '';
            this.delayReason = '';
        },
        AddJustification() {
            this.isLoading = true;
            const data = {
                service_request_id: this.request.id,
                justification_reason_id: this.delayReasonId,
                reason: this.delayReason,
            }
            let justificationReasonErrorElement = document.querySelector('#justification-reason-errors');
            justificationReasonErrorElement.classList.add('d-none');
            justificationReasonErrorElement.innerHTML = '';

            if (this.delayReason == '') {
                console.log('hiii');
                justificationReasonErrorElement.classList.remove('d-none');
                justificationReasonErrorElement.innerHTML = 'Justification Reason Text is Required';
                this.isLoading = false;
                return;
            }

            let url = '';
            switch (this.userType) {
                case 'admin':
                    url = '/admin/service-requests/add-new-delay-reason';
                    break;
                case 'employee':
                    url = '/employee/service-requests/add-new-delay-reason';
                    break;
                case 'outsource':
                    url = '/outsource/service-requests/add-new-delay-reason';
                    break;
            }

            axios.post(url, data,
                {'Content-Type': 'multipart/form-data'})
                .then(res => {
                    this.isLoading = false;
                    this.resetJustification();
                    $('#justification').modal('hide');
                    this.serviceRequest.justification_reason_id = res.data.response.data[0].id;
                    this.serviceRequest.justification = res.data.response.data[0];
                    this.$emit("onRequestChange", this.serviceRequest);

                    $('#response-message').html(`Added Justification Reason for Delayed Service Request`);
                    $('exampleModal').modal('show');

                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500);

                })
                .catch(err => {
                    console.log(err);
                })
            ;
        },
        openSiteVisitModal() {
            $('#siteVisitModal').modal('show');
        },
        callMarkStatusAsSiteVisitApiEndpoint() {
            this.isLoading = true;
            let url = '';
            switch (this.userType) {
                case 'admin':
                    url = `/admin/service-requests/${this.serviceRequest.id}/mark-status-as-site-visit`;
                    break;
                case 'employee':
                    url = `/employee/service-requests/${this.serviceRequest.id}/mark-status-as-site-visit`;
                    break;
                case 'outsource':
                    url = `/outsource/service-requests/${this.serviceRequest.id}/mark-status-as-site-visit`;
                    break;
            }
            axios.get(url)
                .then((res) => {
                    this.isLoading = false;
                    $('#siteVisitModal').modal('hide');
                    $('#response-message').html(`Service Request Has Been Marked as a Site Visit`);
                    $('exampleModal').modal('show');

                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500);
                })
        },
        openNoSolutionModal() {
            $('#noSolutionModal').modal('show');
        },
        callMarkStatusAsNoSolutionApiEndpoint() {
            this.isLoading = true;
            const data = {
                'resolution_note': this.resolutionNote,
            };

            let url = '';
            switch (this.userType) {
                case 'admin':
                    url = `/admin/service-requests/${this.serviceRequest.id}/mark-status-as-no-solution`;
                    break;
                case 'employee':
                    url = `/employee/service-requests/${this.serviceRequest.id}/mark-status-as-no-solution`;
                    break;
                case 'outsource':
                    url = `/outsource/service-requests/${this.serviceRequest.id}/mark-status-as-no-solution`;
                    break;
            }

            axios.post(url, data)
                .then((res) => {
                    this.isLoading = false;
                    $('#noSolutionModal').modal('hide');
                    $('#response-message').html(`Service Request Has Been Marked as Has No Solution`);
                    $('exampleModal').modal('show');

                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                })
            ;
        },
        openReassignServiceRequestModal() {
            $('#reassignServiceRequestModal').modal('show');
        },
        callReassignServiceRequestApiEndpoint() {
            this.isLoading = true;
            const data = {
                'assignee_id': this.assigneeId,
                'assignee_type': this.assignedUserType
            };
            axios.post(`/admin/service-requests/${this.serviceRequest.id}/reassign-service-request`, data)
                .then((res) => {
                    this.isLoading = false;
                    $('#reassignServiceRequestModal').modal('hide');
                    $('#response-message').html(`Service Request Re-assigned Successfully`);
                    $('exampleModal').modal('show');

                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500);
                })
                .then((err) => {
                    console.log(err);
                })
            ;
        },

        changeAssigneeUserType(e) {
            console.log(e.target.value);
            this.assignedUserType = e.target.value;
            switch (e.target.value) {
                case 'admin':
                    this.assigneesList = this.admins;
                    break;
                case 'employee':
                    this.assigneesList = this.employees;
                    break;
                case 'outsource':
                    this.assigneesList = this.outsources;
                    break;
            }
            this.assignedUserId = this.assigneesList[0].id;
        }
    }
}
</script>
