<template>
    <UiTable class="px-0 m-0"
         fullwidth
        :data="data"
        :thead="thead"
        :tbody="tbody"
         selected-key="id"
         show-progress="true"

    >

        <template #th-priority>
            Priority
        </template>
        <template #priority="{ data }">
            <div class="priority-field-wrapper">
                <div class="priority-indicator" :style="`background-color: ${data.priority.color}`">

                </div>
                <div class="priority-name">{{ data.priority.name }}</div>
            </div>
        </template>

        <template #th-update>
            Update
        </template>
        <template #update="{ data }">
            <div class="update">
                <a :href="`/client-users/service-requests/${data.id}`" class="btn btn-primary">Chat</a>
            </div>
        </template>

        <ui-pagination class="p-5"
            v-model="page"
            :total="total"
            show-total
            @update:modelValue="onPage"
            :page-size="pageSize"
        ></ui-pagination>
    </UiTable>
</template>

<script>
import 'balm-ui/dist/balm-ui.css';

export default {
    data() {
        return {
            data: [],
            thead: [
                {
                    value: 'ID',
                    sort: 'asc',
                    columnId: 'id'
                },
                'Title',
                'Details',
                'Server Details',
                'SW Details',
                'SR Priority',
                'Status',
                'Contact',
                {
                    slot: 'th-update',
                    class: 'good',
                    sort: 'asc',
                    columnId: 'update'
                },
            ],
            tbody: [
                {
                    field: 'id',
                    width: 50
                },
                {
                    field: 'title',
                    width: 100
                },
                {
                    field: 'details',
                    width: 100,
                    fn: (data) => {
                        return data.details.length > 20 ? `${data.details.substring(0, 20)}...` : data.details;
                    }
                },
                {
                    field: 'server_details',
                    width: 100,
                    fn: (data) => {
                        return data.server_details.length > 20 ? `${data.server_details.substring(0, 20)}...` : data.server_details;
                    }
                },
                {
                    field: 'software_details',
                    width: 100,
                    fn: (data) => {
                        return data.software_details.length > 20 ? `${data.software_details.substring(0, 20)}...` : data.software_details;
                    }
                },
                // {
                //     field: 'priority',
                //     class: data => {
                //         return data.priority.color;
                //     },
                //     fn: (data) => {
                //         return data.priority.name.charAt(0).toUpperCase() + data.priority.name.slice(1);
                //     }
                // },
                {
                    slot: 'priority'
                },
                {
                    field: 'status',
                    width: 50,
                    fn: (data) => {
                        return data.status_attributes.en_name;
                    }
                },
                'contact',
                {
                    slot: 'update'
                },
            ],
            page: 1,
            currentPage: 1,
            total: 1,
            pageSize: 1,
            meta: {},
        };
    },
    created() {
        this.fetchServiceRequests();
    },
    methods: {
        show(data) {
            console.log(data);
        },
        onPage(page) {
            this.fetchServiceRequests(this.page);
        },
        fetchServiceRequests(page = 1) {
            axios.get(`/client-users/service-requests?page=${this.page}`)
                .then((res) => {
                    this.data = res.data.response.data;
                    let meta = res.data.response.meta;
                    console.log(this.data);
                    console.log(meta);
                    this.total = meta.total;
                    this.pageSize = meta.per_page;
                })
            ;
        }
    },
};

</script>
