<template>
    <div class="send-query-container send-query-client">
        <div class="send-query-content-wrapper p-5">
            <h1 class="text-center text-white">Create Technical SR</h1>
            <div class="text-center pl-4 mb-4">
                <span class="title-left bg-white"></span>
                <span class="title-center bg-white" style="width: 20%;"></span>
                <span class="title-right bg-white"></span>
                <span class="title-chip bg-white" style="width: 20px; height: 20px"></span>
            </div>
            <div class="send-query-form-wrapper">
                <div>
                    <div class="top-background h-auto d-flex align-content-center h3 text-white p-4">What is the problem?</div>

                    <form @submit.prevent id="service-request-form" class="pt-3">
                    <service-request-form-info v-if="status === 'fill-info'" @onStatusChange="updateStatus"
                        :departments="departments"
                        :priorities="priorities"
                    >

                    </service-request-form-info>

                    <service-request-form-question
                        :questions="questions"
                        v-if="status === 'questions'"
                        @sendQuestionsToForm="addQuestionsAndSubmitForm"
                    >

                    </service-request-form-question>
                    </form>

                </div>
            </div>
        </div>
        <div class="overlay"></div>

        <div class="modal" id="error-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <ul id="errors-list">

                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="success-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h2 class="text-center">Service Request Created Successfully</h2>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import ServiceRequestFormInfo from "./ServiceRequestFormInfo";
import ServiceRequestFormQuestion from "./ServiceRequestFormQuestions";
import axios from 'axios';

export default {
    name: "ServiceRequestForm",
    components:{
        ServiceRequestFormInfo,
        ServiceRequestFormQuestion,
    },
    data() {
        return {
            data: [],
            departments: [],
            priorities: [],
            questions: [],
            status: 'fill-info',
            departmentId: '',
            title: '',
            softwareDetails: '',
            serverDetails: '',
            details: '',
            priorityId: '',
            requestQuestions: [],
            csrfToken: document.querySelector('meta[name=csrf-token]').value,
        }
    },

    created() {
        this.fetchServiceRequestRequiredData();
    },

    methods: {
        fetchServiceRequestRequiredData() {
            axios.get(`/client-users/service-requests/get-required-data`)
                .then((res) => {
                    let data = res.data.response.data;
                    this.departments = data.departments;
                    this.priorities = data.priorities;
                    this.questions = data.questions;
                })
            ;
        },
        updateStatus(data){
            let errors = [];
            if (!data.departmentId) {
                errors.push('The department field is required');
            }
            if (!data.title) {
                errors.push('The title field is required');
            }
            if (!data.softwareDetails) {
                errors.push('The software details field is required');
            }
            if (!data.serverDetails) {
                errors.push('The server details field is required');
            }
            if (!data.priorityId) {
                errors.push('The priority field is required');
            }

            if (errors.length > 0) {
                let errorsElementContent = ``;
                errors.forEach((error) => {
                    errorsElementContent += `<li class='text-danger'>${error}</li>`;
                })
                document.querySelector('#errors-list').innerHTML = errorsElementContent;
                $('#error-modal').modal('show');
                return;
            }


            this.departmentId = data.departmentId;
            this.title = data.title;
            this.details = data.details;
            this.softwareDetails = data.softwareDetails;
            this.serverDetails = data.serverDetails;
            this.priorityId = data.priorityId;
            this.status = 'questions';
        },
        addQuestionsAndSubmitForm(data) {
            this.requestQuestions = data.requestQuestions;
            let questions = []
            this.requestQuestions.map((item) => {
                questions.push(item);
            })

            const requestData = {
                department_id: this.departmentId,
                title: this.title,
                details: this.details,
                software_details: this.softwareDetails,
                server_details: this.serverDetails,
                priority_id: this.priorityId,
                answers: questions,
                _token: this.csrfToken
            }

            axios.post('/client-users/service-requests', requestData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then((res) => {
                $('#success-modal').modal('show');
                setTimeout(() => {
                    window.location.href = '/client-users/open-service-request';
                }, 1500);
            })
        }
    }
}
</script>

<style scoped>

</style>
