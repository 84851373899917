<template>
    <div class="accordion" id="accordionExample">
        <ul class="list-group details-list shadow-sm p-4">
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Title</span>: {{ this.request['title'] }}</div></li>
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>User</span>: {{ this.request.user.name }}</div></li>
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Assignee</span>: {{ this.request.assignee.name }}</div></li>
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Department:</span> {{ this.request.department.name }}</div></li>
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Priority</span>: <span style="text-transform: capitalize">{{ this.request['priority'].name }}</span></div></li>
<!--            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Work Field</span>: {{ this.request['work_field'] }}</div></li>-->
<!--            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Total Number Of Visits</span>: {{ this.request['total_number_of_visits'] }}</div></li>-->
<!--            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Total Number Of Used Visits</span>: {{ this.request['total_number_of_used_visits'] }}</div></li>-->
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Status</span>: <span style="text-transform: capitalize">{{ this.request.status_attributes.en_name }}</span></div></li>

            <li class="list-group-item">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left p-0 d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span>Software Details</span>
                                <i class="fa fa-chevron-circle-down"></i>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            {{ this.request['software_details'] }}
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="card">
                    <div class="card-header" id="serverDetails">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left p-0 d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseServerDetails" aria-expanded="true" aria-controls="serverDetails">
                                <span>Server Details</span>
                                <i class="fa fa-chevron-circle-down"></i>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseServerDetails" class="collapse" aria-labelledby="serverDetails" data-parent="#accordionExample">
                        <div class="card-body">
                            {{ this.request['server_details'] }}
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="card">
                    <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left p-0 d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                <span>Details</span>
                                <i class="fa fa-chevron-circle-down"></i>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div class="card-body">
                            {{ this.request['details'] }}
                        </div>
                    </div>
                </div>
            </li>

            <li class="list-group-item">
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left p-0 d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                <span>Resolution Note</span>
                                <i class="fa fa-chevron-circle-down"></i>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body">
                            {{ this.request['resolution_note'] }}
                        </div>
                    </div>
                </div>
            </li>

            <li class="list-group-item">
                <div class="card">
                    <div class="card-header" id="headingFour">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left p-0 d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                <span>Justification</span>
                                <i class="fa fa-chevron-circle-down"></i>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div class="card-body" v-if="this.request.justification">
                            {{ this.request.justification.reason }}
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item card"><div class="card-header border-bottom-0"><span>Closed At:</span> {{ this.request.status === 'completed' || this.request.status === 'site_visit' || this.request.status === 'no_solution' ? this.request['updated_at'] : 'Not Closed Yet' }}</div></li>
        </ul>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        request: {
            type: Object,
        },
    },
    data() {
        return {
        }
    },
    methods: {}
}
</script>
