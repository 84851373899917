<template>
    <Loading v-model:active="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"/>

    <div class="">
        <div class="outer-container mb-2" style="height: 70vh;">
            <ul class="list-unstyled d-flex flex-wrap inner-container h-100 my-2"  id="old-msgs" >
                <li v-for="(message, index) in this.request.messages" :key="index" class="px-5 py-4 w-75 my-3 chat-popup"
                    :class="currentUser.id === message.sender_id ? ' mr-2 ml-auto' : ' mr-auto ml-2'">
                    <div class="d-flex justify-content-between align-items-center pb-3 msg-date">
                        <span>{{message.sender ? message.sender.name : currentUser.name}}</span>
                        <span>{{formatDate(message.created_at)}}</span>
                    </div>
<!--                    <a href="{{message['original_url']}}" class="media" :class="message.media.length > 0 ? '' : 'd-none'">-->

<!--                        <img src="" class="mr-3" alt="...">-->
<!--                        <div class="media-body">-->
<!--                            <h5 class="mt-0">{{message.media['file_name']}}</h5>-->
<!--                        </div>-->
<!--                    </a>-->
                    <div v-for="(media, index) in message.media" :key="index" class="py-4 w-75 my-3">
                        <div v-if="media.mime_type === 'image/png' || media.mime_type === 'image/jpg' || media.mime_type === 'image/jpeg'" class="message-image-file-container">
                            <a target="_blank" :href="media.original_url">
                                <img :src="media.original_url" alt="">
                            </a>
                        </div>
                        <a v-else :href="media['original_url']" download>{{ media.name }}</a>
                    </div>
                    <p v-html="message.message" class="msg"></p>
                </li>
                <li ref="chatBottom" id="chatBottom"></li>

            </ul>
        </div>
        <form @submit.prevent="SendNewMessage" class="mt-5" v-if="request.status_can_be_changed">
            <div class="input-group">
                <textarea class="form-control" aria-label="With textarea" v-model="newMessage"></textarea>

                <div class="input-group-append">
                    <button class="btn btn-outline-secondary px-5" type="submit">Send</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import axios from 'axios';
import {Form} from "vee-validate";
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: {
        Form,
        Loading,
    },
    props: [
        "request",
        "user",
        "userType",
    ],
    data() {
        return {
            currentUser: this.user,
            newMessage: '',
            files: null,
            service_request_id: this.request.id,
            serviceRequest: this.request,
            fullPage: true,
            isLoading: false,
        }
    },
    mounted(){
        this.scrollToElement()

    },
    updated(){
        this.scrollToElement()
    },

    emits: ["onRequestChange"],
    methods: {
        scrollToElement() {
            var targetElm = document.querySelector('#chatBottom');  // reference to scroll target

            targetElm.scrollIntoView();

        },
        SendNewMessage() {
            this.isLoading = true;
            const data = {
                message: this.newMessage,
                files: this.files,
                service_request_id: this.service_request_id
            }
            let url = '';
            switch (this.userType) {
                case 'admin':
                    url = '/admin/send-sr-message-admin';
                    break;
                case 'employee':
                    url = '/employee/send-sr-message';
                    break;
                case 'outsource':
                    url = '/outsource/send-sr-message';
                    break;
            }

            axios.post(url, data,
                { 'Content-Type': 'multipart/form-data' })
                .then(res => {
                    this.isLoading = false;
                    this.newMessage = '';
                    this.serviceRequest = res.data.response.data.SR;
                    this.$emit("onRequestChange", this.serviceRequest);
                    $('#response-message').html(`Message Sent Successfully`);
                    $('exampleModal').modal('show');

                    setTimeout(() => {
                        window.location.href = window.location.href;
                    }, 1500);
                })
                .catch(err => {
                    console.log(err);
                })
            ;
        },
        formatDate(date) {
            let time = moment(date);
            return time.format('dddd, MMMM Do YYYY, h:mm a');
        },

    }
}
</script>

<style scoped>

    .message-image-file-container {
        width: 75%;
    }

    .message-image-file-container img {
        width: 100%;
        overflow: hidden;
    }

</style>
