<template>
    <div class="send-query-container">
        <div class="send-query-content-wrapper">
            <h1 class="text-center text-white">Service Request</h1>
            <div class="text-center pl-4 mb-4">
                <span class="title-left bg-white"></span>
                <span class="title-center bg-white" style="width: 20%;"></span>
                <span class="title-right bg-white"></span>
                <span class="title-chip bg-white" style="width: 20px; height: 20px"></span>
            </div>
            <div class="send-query-form-wrapper">
                <div class="top-background h-auto d-flex align-content-center h3 text-white p-4">Service Request Home</div>
                <div>
                    <div class="p-3">
                        <a class="btn btn-general mr-2" href="/client-users/create-service-request">Create technical SR</a>
                    </div>
                    <div>
                        <div class="demo-content">
                            <ui-button @click="open = true"><ui-icon>storage</ui-icon></ui-button>
                            <ui-button><ui-icon class="text-warning">star_rate</ui-icon></ui-button>
                            <ui-menu
                                v-model="open"
                                :items="[
                                      'Back',
                                      'Forward',
                                      'Reload',
                                      '-',
                                      'Help &amp; Feedback',
                                      'Settings'
                                    ]"
                            ></ui-menu>
                        </div>
                    </div>
                    <ServiceRequestHistoryTable></ServiceRequestHistoryTable>
                </div>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
</template>

<script>

import ServiceRequestHistoryTable from "./ServiceRequestHistoryTable";

export default {
    components: {
        ServiceRequestHistoryTable,
    },
    props: {

    },
    data() {
        return {
            open: false

        }
    },
    methods: {

    }
}
</script>
