<template>
    <div class="container pt-5">
        <h3>Enter Registered User Data</h3>
        <Form @submit.prevent :validation-schema="validationSchema">
            <div class="form-group my-4">
                <Field type="text" class="form-control custom-input" name="username" placeholder="Mobile Number" v-model="username" />
                <ErrorMessage class="text-danger" name="username" />
                <p class="text-danger">{{ badRequestMessage }}</p>
            </div>
            <div class="form-group">
                <Field type="password" class="form-control custom-input" name="password" placeholder="Password" v-model="password" />
                <ErrorMessage class="text-danger" name="password" />
            </div>
            <div class="text-left my-4">
                <button class="btn btn-primary custom-btn" @click="callClientUserCredentialsVerificationApi">Submit</button>
            </div>
        </Form>

        <div class="modal fade" id="verification-code-modal" tabindex="-1" role="dialog" aria-labelledby="verification-code-modal-label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4 class="text-center">Please enter token number sent to your mobile</h4>
                        <Form @submit.prevent :validation-schema="validationSchema">
                            <div class="form-group my-4">
                                <CodeInput
                                    @complete="markVerificationCodeInputAsCompleted"
                                    :fields="6"
                                    :fieldWidth="56"
                                    :fieldHeight="56"
                                    :required="true"
                                    @change="changeVerificationCodeValue"
                                />
                            </div>
                            <div class="text-center my-4">
                                <button class="btn btn-primary text-button" @click="callResendVerificationCodeApi">Resend Verification Code</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import axios from 'axios';
    import * as yup from 'yup';
    import CodeInput from './VerificationCodeInput';
    let completed = ref(false);

    export default {
        components: {
            Form,
            Field,
            ErrorMessage,
            CodeInput,
        },
        data() {
            const validationSchema = yup.object({
                username: yup.string().matches('01(0|1|2|5)[0-9]{8}', 'The Mobile Number You Entered is Invalid').required('The Mobile Number is Required'),
                password: yup.string().required('The Password is Required'),
                verification_code: yup.string().required('The Verification Code is Required'),
            })
            return {
                username: '',
                password: '',
                verificationCode: '',
                validationSchema,
                badRequestMessage: '',
                userLoggedIn: false,
                csrfToken: document.querySelector('meta[name=csrf-token]').value,
                completed: completed,
            }
        },
        methods: {
            callClientUserCredentialsVerificationApi() {
                const data = {
                    username: this.username,
                    password: this.password,
                    _token: this.csrfToken,
                }

                axios.post('/client-users/verify-client-user-credentials', data)
                    .then(res => {
                        $('#verification-code-modal').modal('show');
                    })
                    .catch(err => {
                        if (err.response.data.status == 400) {
                            const errorMessage = err.response.data.message;
                            this.badRequestMessage = errorMessage;
                        }
                    })
                ;
            },
            callClientUserLoginVerificationApi() {
                const data = {
                    username: this.username,
                    password: this.password,
                    verification_code: this.verificationCode,
                    _token: this.csrfToken,
                }

                axios.post('/client-users/login', data)
                    .then(res => {
                        this.userLoggedIn = true;
                        window.location.href = window.location.href;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                ;

            },
            callResendVerificationCodeApi() {
                const data = {
                    username: this.username,
                    _token: this.csrfToken,
                }

                axios.post('/client-users/resend-verification-code', data)
                    .then(res => {
                        alert('Verification Code Sent to Your Mobile Number');
                    })
                    .catch(err => {
                        console.log(err);
                    })
                ;
            },
            changeVerificationCodeValue(e) {
              this.verificationCode = e;
            },
            markVerificationCodeInputAsCompleted() {
                this.completed = true;
                this.callClientUserLoginVerificationApi();
            }
        }
    }
</script>

<style scoped>
    .modal-dialog {
        max-width: 70vw !important;
    }
    .modal-content {
        border-top-left-radius: 10rem;
        border-bottom-right-radius: 10rem;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding: 5rem;
    }

    .text-button {
        padding: 0 !important;
        border: none !important;
        text-decoration: underline;
        color: #A1A1A1 !important;
        background-color: #FFF !important;
    }
</style>
