<template>
    <form class="w-100">
        <div class="form-group px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <label><span class="text-danger">*</span>Department:</label>
                </div>
                <div class="col-10">
                    <select name="department" id="department" class="form-control" v-model="departmentId">
                        <option v-for="department in departments" :value='department.id'>
                            {{department.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <label><span class="text-danger">*</span>Title:</label>
                </div>
                <div class="col-10">
                    <input class="form-control"
                           v-model="title"
                    />
                </div>
            </div>
        </div>
        <div class="form-group px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <label><span class="text-danger">*</span>Details:</label>
                </div>
                <div class="col-10">
                    <textarea
                        v-model="details"
                        name="details"
                        rows="5"
                        class="form-control"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="form-group px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <label><span class="text-danger">*</span>Server Details:</label>
                </div>
                <div class="col-10">
                    <textarea class="form-control"
                           rows="5"
                           v-model="serverDetails"
                    ></textarea>
                </div>
            </div>

        </div>
        <div class="form-group px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <label><span class="text-danger">*</span>S/W Details:</label>
                </div>
                <div class="col-10">
                    <textarea class="form-control"
                           rows="5"
                           v-model="softwareDetails"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="form-group px-5 py-3">
            <div class="row">
                <div class="col-2">
                    <label>SR Priority:</label>
                </div>
                <div class="col-10">
                    <div>
                        <div class="custom-control custom-radio custom-control-inline" v-for="priority in priorities" :key="priority.id">
                            <input :id="`radio-${priority.id}`" type="radio" :value="priority.id" v-model="priorityId" class="custom-control-input" />
                            <label style="text-transform: capitalize" :for="`radio-${priority.id}`" class="custom-control-label">{{ priority.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group px-5 py-3 text-right">
            <button class="btn custom-btn custom-btn-left-curve mx-5" type="button" @click="nextButtonClicked">
                Next
            </button>
        </div>
    </form>
</template>


<script>

export default {
    name: "ServiceRequestForm",
    data() {
        return {
            departmentId: '',
            title: '',
            details: '',
            serverDetails: '',
            softwareDetails: '',
            priorityId: '',
            questions: [],
        }
    },
    props: [
        "departments",
        "priorities"
    ],
    created() {

    },

    emits: ["onStatusChange"],

    methods: {
        nextButtonClicked() {
            this.$emit("onStatusChange", {
                departmentId: this.departmentId,
                title: this.title,
                details: this.details,
                serverDetails: this.serverDetails,
                softwareDetails: this.softwareDetails,
                priorityId: this.priorityId,
            });
        },
        changeInputValue(e) {
            console.log(e);
        }
    },
}
</script>
