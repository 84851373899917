require('./bootstrap');

import { createApp } from 'vue';
import LoginScreen from "./components/LoginScreen";
import ServiceRequestAdmin from "./components/serviceRequestAdmin";
import SendQueryForm from "./components/SendQueryForm";
import ServiceRequestHistory from "./components/ServiceRequest/ServiceRequestHistory";
import ServiceRequestHistoryTable from "./components/ServiceRequest/ServiceRequestHistoryTable";
import ServiceRequestForm from "./components/ServiceRequest/ServiceRequestForm";
import ServiceRequestFormInfo from "./components/ServiceRequest/ServiceRequestFormInfo";
import ServiceRequestFormQuestions from "./components/ServiceRequest/ServiceRequestFormQuestions";
import ServiceRequestClientChat from "./components/ServiceRequest/ServiceRequestClientChat";
import TraineeLoginScreen from "./components/TraineeLoginScreen.vue";

import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui/dist/balm-ui-plus'; // BalmJS Team Material Components

const  app = createApp({
    components: {
        LoginScreen,
        ServiceRequestAdmin,
        SendQueryForm,
        ServiceRequestHistory,
        ServiceRequestHistoryTable,
        ServiceRequestForm,
        ServiceRequestFormInfo,
        ServiceRequestFormQuestions,
        ServiceRequestClientChat,
        TraineeLoginScreen
    },
});

app.use(BalmUI); // Mandatory
app.use(BalmUIPlus); // Optional

app.mount('#app')
